import { graphql, useStaticQuery } from "gatsby";

export const ArchiveData = () => {

  const data = useStaticQuery(graphql`
    query {
        allMdx(sort: {fields: [frontmatter___date], order: DESC}, filter: {slug: {regex: "/blog//"}}) {
          edges {
            ...ListingItemBasicData
          }
          totalCount
        }
      }
    `)

  return data;
}

