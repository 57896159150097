import React from "react";

import {
  Container,
  Layout,
  ListingItem,
  PostsGrid,
} from "../components/page";
import { SEO } from "../components/metadata/index";

import { SiteData } from "../hooks/site-data";
import { ArchiveData } from "../hooks/archive-data";

const Archive = () => {
  const siteData = SiteData()
  const siteTitle = siteData.site.siteMetadata.title
  const posts = ArchiveData().allMdx.edges

  return (
    <Layout title={siteTitle}>
      <SEO title="/archive" />
      <Container>
        <PostsGrid>
          {posts.map(({ node }) => {
            return <ListingItem node={node} key={node.id} />
          })}
        </PostsGrid>
      </Container>
    </Layout>
  )
}

export default Archive
